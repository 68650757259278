import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

import Seo from "../components/seo"
import Layout_page from "../components/layout_page";

export default function MotesprotokollPage() {

  const data = useStaticQuery(
    graphql`
     query {
        allMarkdownRemark (
          filter: {fileAbsolutePath: {regex: "/\/files\//"}}
          sort: {fields: frontmatter___date_meeting, order: DESC}
          ) {
            nodes {
                frontmatter {
                title
                date_meeting(formatString: "YYYY-MM-DD")
                file {
                  base
                }
              }
            }
        }
     }
    `
  );
  const files = data.allMarkdownRemark.nodes;

  return (


    <div className="bg-def_grey_1 page_formatting">

      <Layout_page>

        <h1 className=""><FontAwesomeIcon className="pr-4" icon={solid('gavel')} />Mötesprotokoll</h1>


          <table className="table-auto border-separate border-spacing-x-4 sm:border-spacing-x-20 border-spacing-y-12 text-left mt-8 bg-neutral-800 text-white rounded-lg">
            <thead>
              <tr>
                <th colspan="2">Titel</th>
                <th>Datum</th>
                <th></th>
              </tr>
            </thead>
            <tbody>

              {files.map((files) => {
                const { frontmatter } = files;

                let pill = "";
                if (frontmatter.title === "Styrelsemöte") {
                  pill = "bg-blue-800"
                }
                else if (frontmatter.title === "Årsmöte") {
                  pill = "bg-yellow-800"
                }
                else if (frontmatter.title === "Stadgar") {
                  pill = "bg-purple-800"
                }
                else {
                  pill = "bg-gray-600"
                }

                return (
                  <tr key={frontmatter.file.base.toString()} className="border-b-2 border-black">
                    <td className="">
                      <FontAwesomeIcon className="text-md text-2xl text-neutral-300" icon={solid('file')} />
                    </td>
                    <td>
                      <span className={`px-2 py-1 rounded-full ${pill}`}>
                      {frontmatter.title}

                      </span>
                    </td>
                    <td>
                      <p>{frontmatter.date_meeting}</p>
                    </td>
                    <td>
                      <a href={`/files/motesprotokoll/${frontmatter.file.base}`} className="bg-def_yellow_1 hover:bg-def_yellow_2 text-def_grey_1 px-3 py-2 font-medium rounded-lg no-underline shadow-md" target="blank">Visa</a>
                    </td>
                  </tr>
                );
              })}
            </tbody>

          </table>
      </Layout_page>
    </div>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Mötesprotokoll" />
